<template>
  <div class="Appropriationprogress">
    <div class="Appropriationprogress_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="Appropriationprogress_right">
      <div class="Appropriationprogress_head">
        <div class="Appropriationprogress_head1">
          <span>开始时间:</span>
          <el-date-picker v-model="time1" type="date" placeholder="选择日期">
          </el-date-picker>
          <span>结束时间:</span>
          <el-date-picker v-model="time2" type="date" placeholder="选择日期">
          </el-date-picker>
          <el-button type="primary" icon="el-icon-search" @click="search"
            >搜索</el-button
          >
          <el-button type="primary" icon="el-icon-refresh-right" @click="Reset"
            >重置</el-button
          >
        </div>
        <div class="Appropriationprogress_head2">
          <el-button
            type="primary"
            icon="el-icon-plus"
            @click="addappropriation"
            >添加拨款</el-button
          >
        </div>
      </div>
      <div class="Appropriationprogress_middle">
        <span>预算总额:</span>
        <span style="color: #3388ff">{{ obj.TopicFinanc }} (万元)</span>
        <span>拨款余额:</span>
        <span style="color: #3388ff">{{ obj.TopicBalance }} (万元)</span>
        <span>拨款进度:</span>
        <span
          ><el-progress
            :percentage="obj.TopicProgress"
            :stroke-width="20"
            style="width: 250px"
            :text-inside="true"
            status="warning"
          ></el-progress
        ></span>
        <!-- <el-progress :text-inside="true" :stroke-width="26" :percentage="70" status="success"></el-progress> -->
      </div>
      <div class="Appropriationprogress_body">
        <el-table
          :data="tableData"
          :header-cell-style="{
            color: '#333333',
            backgroundColor: '#FBFBFD',
          }"
          border
          style="width: 100%"
        >
          <el-table-column prop="HospitalName" label="单位" align="center">
          </el-table-column>
          <el-table-column prop="SerialNumber" label="课题序号" align="center">
          </el-table-column>
          <el-table-column
            prop="AmountReceived"
            label="收款总额（万元）"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="ArrivalDate" label="到账日期" align="center">
          </el-table-column>
          <!-- <el-table-column label="到账日期" align="center">
                            <template slot-scope="scope">
                                   <p>2020-11-11 100万</p>
                                   <p>2020-11-11 100万</p>
                                   {{scope.$index}}
                            </template>
                            </el-table-column> -->
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link
                style="margin-right: 11px"
                type="text"
                :underline="false"
                @click.stop="handleEdit(scope.row)"
                ><i class="el-icon-edit-outline"></i>编辑</el-link
              >
              <el-link
                style="margin-right: 11px"
                type="text"
                :underline="false"
                @click.stop="handleDelete(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-link
              >
              <el-link
                style="margin-right: 10px"
                type="text"
                :underline="false"
                @click="seemilepostDialog(scope.row)"
                ><img
                  src="../../../assets/image/notice/query.png"
                  alt=""
                  style="width: 16px; height: 16px; vertical-align: -3px"
                />查看</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <pagination
          :total="total"
          :page.sync="listQuery.pageNum"
          :limit.sync="listQuery.pageSize"
          @pagination="getList"
        >
        </pagination>
      </div>
    </div>
    <div class="Appropriationprogressdialog">
      <el-dialog
        @close="closedialog('addForm')"
        width="500px"
        :visible.sync="Appropriationprogressdialog"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">添加拨款</span>
        </div>
        <div class="body">
          <el-form
            label-width="100px"
            :rules="rules"
            ref="addForm"
            :model="fromobj"
          >
            <el-form-item label="预算总额:">
              <el-input
                v-model="TopicFinanc"
                disabled
                style="width: 310px"
              ></el-input>
              <span>万元</span>
            </el-form-item>
            <el-form-item label="收款总额:" prop="AmountReceived">
              <el-input
                v-model="fromobj.AmountReceived"
                style="width: 310px"
                @change="AmountReceived"
              ></el-input>
              <span>万元</span>
            </el-form-item>
            <el-form-item label="到账时间:" prop="ArrivalDate">
              <el-date-picker
                v-model="fromobj.ArrivalDate"
                type="date"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="相关文件:">
              <el-upload
                class="upload-demo"
                ref="plannewupload"
                accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc"
                multiple
                action="#"
                :file-list="Appropriationlist"
                :auto-upload="false"
                :on-change="oncheng"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="cancel('addForm')">取消</el-button>
          <el-button @click="addAppropriationprogress('addForm')"
            >确定</el-button
          >
        </div>
      </el-dialog>
    </div>
    <!-- 编辑 -->
    <div class="Appropriationprogressdialog">
      <el-dialog width="500px" :visible.sync="editdialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">编辑拨款</span>
        </div>
        <div class="body">
          <el-form label-width="100px">
            <el-form-item label="预算总额:">
              <el-input
                v-model="TopicFinanc"
                disabled
                style="width: 310px"
              ></el-input>
              <span>万元</span>
            </el-form-item>
            <el-form-item label="收款总额:">
              <el-input
                v-model="fromobj.AmountReceived"
                style="width: 310px"
              ></el-input>
              <span>万元</span>
            </el-form-item>
            <el-form-item label="到账时间:">
              <el-date-picker
                v-model="fromobj.ArrivalDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="相关文件:">
              <div style="display: flex; flex-wrap: wrap">
                <div
                  class="del-img"
                  v-for="(item, index) in filelist"
                  :key="index"
                >
                  <p class="del-icon" @click="deletefile(item.Id)">x</p>
                  <img src="../../../assets/image/mor.png" />
                  <el-tooltip placement="top">
                    <div slot="content">
                      {{ item.FileName }}.{{ item.Suffix }}
                    </div>
                    <span>{{ item.FileName }}.{{ item.Suffix }}</span>
                  </el-tooltip>
                </div>
                <el-upload
                  class="upload-demo"
                  ref="newupload"
                  accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
                  multiple
                  action="#"
                  :file-list="Appropriationlist"
                  :auto-upload="false"
                  :on-change="oncheng"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="btn">
          <el-button @click="editdialog = false">取消</el-button>
          <el-button @click="okAppropriationprogress">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="deleteAppropriationprogressdialog">
      <el-dialog
        width="450px"
        :visible.sync="deleteAppropriationprogressdialog"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div class="body">
          <span>!</span><span>此操作将永久删除该拨款进度,是否继续？</span>
        </div>
        <div class="btn">
          <el-button @click="deleteAppropriationprogressdialog = false"
            >取消</el-button
          >
          <el-button @click="deleteAppropriationprogress">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="deleteAppropriationprogressdialog">
      <el-dialog width="450px" :visible.sync="filedeletedialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div class="body">
          <span>!</span><span>此操作将永久删除文件,是否继续？</span>
        </div>
        <div class="btn">
          <el-button @click="filedeletedialog = false">取消</el-button>
          <el-button @click="okdelete">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="filedialog">
      <el-dialog width="550px" :visible.sync="filedialog" v-dialogDrag>
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div class="body">
          <el-table :data="fileData" max-height="500px" style="width: 100%">
            <el-table-column
              prop="FileName"
              label="文件名称"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="Suffix"
              label="文件格式"
              width="180"
              align="center"
            >
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-link
                  style="margin-right: 10px"
                  type="text"
                  :underline="false"
                  @click="fliesee(scope.row)"
                  ><img
                    src="../../../assets/image/notice/query.png"
                    alt=""
                    style="width: 16px; height: 16px; vertical-align: -3px"
                  />查看</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!-- <div class="btn">
                  <el-button @click="filedialog=false">取消</el-button>
                  <el-button @click="deleteAppropriationprogress">确定</el-button>
                </div> -->
      </el-dialog>
    </div>
  </div>
</template>
<script>
import reseacrh from "../../../api/research.js"
import pagination from "../../../components/Pagination/index.vue";
import treedata from "../../../components/treedata.vue";
import api from "../../../api/Grantsandmilestones";
import {downloadA, downloadFileId,downLoadXls} from "@/utils/downloadUrl";
import { mapState } from "vuex";
import { ruleData1 } from "../../../utils/validationRules";
export default {
  data() {
    return {
      fileData: [],
      filedialog: false,
      rules: ruleData1,
      input: "",
      Appropriationlist: [],
      TopicFinanc: "",
      fromobj: {
        AmountReceived: "",
        ArrivalDate: "",
      },
      tableData: [],
      filearr: [],
      obj: {},
      time1: "", //搜索日期
      time2: "",
      Appropriationprogressdialog: false, //拨款进度弹框
      editdialog: false,
      deleteAppropriationprogressdialog: false, //删除拨款进度弹框
      filedeletedialog: false, //删除文件弹框
      filelist: [],
      id: "", //拨款进度id
      delFileId: "", //文件id
      total: 0,
      listQuery: {
        pageNum: 1,
        pageSize: 20,
      },
      DataGuid: "",
    };
  },
  components: {
    treedata,
    pagination,
  },
  computed: mapState(["projectId"]),
  created() {
    this.infoAppropriationData();
    this.pagingmilepostarr();
  },
  methods: {
    AmountReceived(e) {
      let flag = /^\d+(\.\d+)?$/;
      if (!flag.test(e)) {
        alert("请填写数字或者小数")
      }
    },
    pre(item){ // 点开查看文件
      console.log(item,'pre');
      reseacrh.preview(item.Id).then(res=>{
        this.downloadfile(item.Id,true)
      })
    },
    fliesee(item) {
      console.log(item,'查看');
      api.previewobj(item.Id).then((res) => {
        console.log(res);
        if(res.data.Status==1){
          this.showFileList=res.data.ExtraData
          this.pre(this.showFileList)
          
        }else{
          alert(res.data.Message)
        }
      });
    },
    downloadfile(id,preview=false){
      downloadFileId(id,preview)
    },
    closedialog(formName) {
      //关闭回调
      this.$refs[formName].resetFields();
      this.Appropriationprogressdialog = false;
    },
    cancel(formName) {
      //取消
      this.$refs[formName].resetFields();
      this.Appropriationprogressdialog = false;
    },
    Reset() {
      //重置
      this.pagingmilepostarr();
      this.time1 = "";
      this.time2 = "";
    },
    seemilepostDialog(item) {
      this.filedialog = true;
      api.previewarr(item.DataGuid).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.fileData = res.data.ExtraData;
        } else {
          alert(res.data.Message);
        }
      });
    },
    getList(val) {
      //分页组件事件
      this.listQuery.pageNum = val.page;
      this.pagingmilepostarr();
      this.search();
    },
    projectIdClick(data) {
      //树型结构传递过来的课题id
      this.$store.commit("SaveId", data);
      this.infoAppropriationData();
      this.pagingmilepostarr();
    },
    search() {
      //搜索
      let parame = {
        TopicId: this.projectId,
        key: "",
        PageIndex: this.listQuery.pageNum * 1 - 1 * 1,
        PageSize: this.listQuery.pageSize,
        SerialNumber: "",
        HospitalName: "",
        StartDate: this.time1,
        EndDate: this.time2,
      };
      api.pagingmilepostData(parame).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.tableData = res.data.Entity.map((item) => {
            item.ArrivalDate = item.ArrivalDate.substr(0, 10);
            return item;
          });
        } else {
          alert(res.data.Message);
        }
      });
    },
    pagingmilepostarr() {
      let parame = {
        TopicId: this.projectId,
        key: "",
        PageIndex: this.listQuery.pageNum * 1 - 1 * 1,
        PageSize: this.listQuery.pageSize,
      };
      api.pagingmilepostData(parame).then((res) => {
        console.log(res, "拨款分页");
        if (res.data.Status == 1) {
          this.total = res.data.TotalRowsCount;
          this.tableData = res.data.Entity.map((item) => {
            item.ArrivalDate = item.ArrivalDate.substr(0, 10);
            return item;
          });
        } else {
          alert(res.data.Message);
        }
      });
    },
    infoAppropriationData() {
      api.AppropriationData(this.projectId).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.TopicFinanc = res.data.Entity.TopicFinanc;
          this.obj = res.data.Entity;
        } else {
          alert(res.data.Message);
        }
      });
    },
    addappropriation() {
      //打开拨款进度弹框
      this.Appropriationlist = [];
      this.fromobj.AmountReceived = "";
      this.fromobj.ArrivalDate = "";
      this.Appropriationprogressdialog = true;
    },
    addAppropriationprogress(formName) {
      //确定新增拨款弹框
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            Id: 0,
            UserId: 0,
            DataGuid: 0,
            // CreateDate:"",//创建日期
            TopicId: this.projectId,
            AmountReceived: this.fromobj.AmountReceived,
            ArrivalDate: this.fromobj.ArrivalDate,
            Remarks: "",
          };
          let fd = new FormData();
          this.filearr.forEach((file) => {
            fd.append("file", file.raw);
          });
          fd.append("data", JSON.stringify(params));
          api.addAppropriationData(fd).then((res) => {
            console.log(res, "新增拨款");
            if (res.data.Status == 1) {
              this.$refs[formName].resetFields();
              this.$emit("paper-child", true);
              this.Appropriationprogressdialog = false;
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.infoAppropriationData();
              this.pagingmilepostarr();
            } else {
              alert(res.data.Message);
            }
          });
          return true;
        } else {
          this.$message.error("注意必填项");
          return false;
        }
      });
    },
    handleEdit(item) {
      //打开编辑弹框
      console.log(item);
      this.id = item.Id;
      this.fromobj.AmountReceived = item.AmountReceived;
      this.fromobj.ArrivalDate = item.ArrivalDate;
      this.editdialog = true;
      this.DataGuid = item.DataGuid;
      this.tableDatalist(item.DataGuid);
    },
    tableDatalist(item) {
      //文件列表
      api.preFinancFile(item).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.filelist = res.data.ExtraData;
        } else {
          alert(res.data.Message);
        }
      });
    },
    okAppropriationprogress() {
      //确定编辑
      let params = {
        Id: this.id,
        UserId: 0,
        DataGuid: 0,
        CreateDate: "", //创建日期
        TopicId: this.projectId,
        AmountReceived: this.fromobj.AmountReceived,
        ArrivalDate: this.fromobj.ArrivalDate,
        Remarks: "",
      };
      let fd = new FormData();
      this.filearr.forEach((file) => {
        fd.append("file", file.raw);
      });
      fd.append("data", JSON.stringify(params));
      api.UpdateAppropriationData(fd).then((res) => {
        console.log(res, "修改拨款进度");
        if (res.data.Status == 1) {
          this.editdialog = false;
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.infoAppropriationData();
          this.pagingmilepostarr();
        } else {
          alert(res.data.Message);
        }
      });
      return true;
    },
    handleDelete(item) {
      //打开删除拨款进度弹框
      this.id = item.Id;
      this.deleteAppropriationprogressdialog = true;
    },
    deleteAppropriationprogress() {
      //确定删除拨款进度弹框
      let params = {
        Id: this.id,
      };
      api.DeleteAppropriationData(params).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.deleteAppropriationprogressdialog = false;
          this.infoAppropriationData();
          this.pagingmilepostarr();
          this.$message({
            message: "删除成功",
            type: "success",
          });
        } else {
          alert(res.data.Message);
        }
      });
    },
    deletefile(id) {
      //删除文件弹框
      this.delFileId = id;
      this.filedeletedialog = true;
    },
    okdelete() {
      //确定删除
      let params = {
        Id: this.delFileId,
      };
      api.alldelet(params).then((res) => {
        console.log(res);
        if (res.data.Status == 1) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.tableDatalist(this.DataGuid);
          this.filedeletedialog = false;
        } else {
          alert(res.data.Message);
        }
      });
    },
    oncheng(file, fileList) {
      this.filearr = fileList;
      console.log(file, fileList);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../styles/mixin.scss";

/deep/.el-progress-bar__inner{
  background: linear-gradient(242deg, #3BECF2 0%, #3388FF 100%);
}

.Appropriationprogress_body {
  width: 100%;
  height: 630px;
  overflow: auto;
}
::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
  height: 10px; /*对水平流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track {
  background-color: #fff;
  border-radius: 3px;
}
/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #1677ff;
}
.Appropriationprogress {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  .Appropriationprogress_left {
    width: 250px;
    margin-top: 2%;
    border-radius: 10px;
    background: white;
  }
  .Appropriationprogress_middle {
    display: flex;
    margin-bottom: 10px;
    // justify-content:center;
    span {
      color: #666666;
      font-weight: 400;
      margin-left: 10px;
      margin-right: 8px;
      @include add-size($font_size_16);
    }
  }
  .Appropriationprogress_right {
    width: 86%;
    height: 94%;
    margin-left: 24px;
    margin-top: 2%;
    margin-right: 24px;
    background: #ffffff;
    padding: 1% 1% 0 1%;
    border-radius: 10px;
    box-sizing: border-box;
    .Appropriationprogress_head {
      display: flex;
      margin-bottom: 20px;
      .Appropriationprogress_head1 {
        width: 70%;
        span {
          color: #666666;
          font-weight: 400;
          margin-left: 10px;
          margin-right: 10px;
          @include add-size($font_size_16);
        }
        .el-input {
          width: 200px;
        }
        .el-button {
          margin-left: 20px;
        }
      }
      .Appropriationprogress_head2 {
        width: 30%;
        text-align: right;
      }
    }
  }
}
.deleteAppropriationprogressdialog {
  .body {
    text-align: center;
    span:nth-child(1) {
      display: inline-block;
      width: 26px;
      line-height: 26px;
      border-radius: 50%;
      background: #ffba00;
      text-align: center;
      color: #fff;
      margin-right: 10px;
      vertical-align: middle;
    }
    span:nth-child(2) {
      display: inline-block;
      vertical-align: middle;
      color: #666666;
      @include add-size1($font_size_18);
    }
  }
}
.del-img {
  //width: 70px;
  width: 120px;
  height: 100px;
  //font-size: 0;
  position: relative;
  margin-right: 6px;
  margin-bottom: 6px;
  .del-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    font-size: 20px;
    top: -6px;
    right: -4px;
    color: white;
    z-index: 99999999;
    background: #666666;
    text-align: center;
    line-height: 20px;
    border-radius: 50%;
  }
  img {
    position: absolute;
    width: 120px;
    height: 70px;
    z-index: 2;
  }
  .tubiao {
    font-size: 70px;
    width: 120px;
  }
  span {
    position: absolute;
    bottom: 0;
    top: 70px;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    width: 120px;
    height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
/deep/.el-progress-bar__outer {
  box-shadow: 0px -4px 29px rgba(0, 0, 0, 0.1);
}
/deep/.el-progress-bar__innerText {
  color: #000;
  font-size: 14px;
}
</style>
